import styled from "styled-components"

export default styled.section`
  margin: ${({ margin = "1rem" }) => margin};
  padding: ${({ padding = "1rem" }) => padding};
  background: white;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  border-radius: ${({ borderRadius = "2px" }) => borderRadius};
`

export const CardInner = styled.div`
  padding: 1.5rem;
`
