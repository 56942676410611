import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { navigate } from "gatsby"
import Img from "gatsby-image"
import Button from "../button"
import Card, { CardInner } from "../card"
import Flex from "../flex"
import Typography from "../typography"

const StyledTitlesWrapper = styled.div`
  @media (min-width: 600px) {
    min-height: 60px;
  }
`

const TeamProfile = ({ name, crm, titles, slug, image }) => (
  <Flex child flexGtSm={33} flexSm={50} flex={100}>
    <Card
      onClick={() => navigate(`/equipe/${slug}`)}
      margin="0.5rem"
      padding="0"
    >
      <Img
        fluid={image.node.fluid}
        imgStyle={{ objectFit: "cover", width: "100%" }}
        alt={name}
      />
      <CardInner>
        <Typography color="secondary" variant="h5" component="h2">
          {name}
        </Typography>
        <Typography variant="h6" color="primary" component="p">
          {`CRM ${crm}`}
        </Typography>
        <StyledTitlesWrapper>
          {titles.map(({ rqe, label }) => (
            <Typography key={rqe} variant="body" component="p" removeMargin>
              {`${label} (RQE ${rqe})`}
            </Typography>
          ))}
        </StyledTitlesWrapper>
        <Button
          to={`/equipe/${slug}`}
          htmlType="link"
          color="primary"
          shape="round"
          block
        >
          Ver currículo
        </Button>
      </CardInner>
    </Card>
  </Flex>
)

TeamProfile.propTypes = {
  name: PropTypes.string.isRequired,
  crm: PropTypes.string.isRequired,
  titles: PropTypes.arrayOf(PropTypes.object).isRequired,
  slug: PropTypes.string.isRequired,
  image: PropTypes.objectOf(PropTypes.any).isRequired,
}

export default TeamProfile
