import React from "react"
import styled from "styled-components"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Section from "../../components/section"
import Container from "../../components/container"
import Flex from "../../components/flex"
import Typography from "../../components/typography"
import TeamProfile from "../../components/teamProfile"
import doctors from "../../api/doctors"
import { useImagesFluid } from "../../hooks/useImages"
import Image from "../../assets/images/equipe_gastrocirurgica_cirurgia.png"

const StyledWrapperImage = styled.div`
  @media (min-width: 960px) {
    margin-bottom: -52rem;
  }
  @media (max-width: 960px) {
    margin-bottom: 2.5rem;
  }
`

const StyledImage = styled.img`
  object-fit: cover;
  object-position: top center;
  width: 100%;
  height: 100%;
  border-radius: 50px 0 50px 0;
  @media (min-width: 960px) {
    height: 300px;
  }
`

const StyledSection = styled(Section)`
  @media (min-width: 960px) {
    padding-top: 10rem !important;
  }
`

const findImage = ({ images, name }) =>
  images.find(({ node }) => name === node.fluid.originalName)

const names = doctors.map(({ image }) => image)

const Team = props => {
  const images = useImagesFluid({ names })
  return (
    <Layout {...props}>
      <SEO
        title="Médicos cirurgiões do Aparelho Digestivo em Florianópolis/SC"
        description="Experiente e capacitada para diagnosticar, realizar tratamento clínico e cirúrgico de diversas enfermidades relacionadas ao aparelho digestivo."
      />
      <Section background="secondary" padding="2.5rem 0">
        <Container>
          <Flex flexWrap="wrap" align="flex-start" justify="space-between">
            <Flex child flex={100} flexGtSm={40}>
              <StyledWrapperImage>
                <StyledImage
                  src={Image}
                  alt="Equipe GastroCirúrgica com traje de cirúrgia"
                />
              </StyledWrapperImage>
            </Flex>
            <Flex child flex={100} flexGtSm={55}>
              <Typography variant="h2" component="h1" color="light">
                {`Conheça a equipe de médicos cirurgiões`}
              </Typography>
              <Typography variant="bodyLarge" color="light">
                {`
                Experiente e capacitada para diagnosticar, realizar tratamento clínico e cirúrgico de diversas enfermidades relacionadas ao aparelho digestivo.
                `}
              </Typography>
              <Typography variant="bodyLarge" color="light">
                {`
                Dominam as últimas tecnologias e estão em constante evolução, trazendo o que há de mais moderno em soluções de saúde através de um atendimento de excelência.
                `}
              </Typography>
            </Flex>
          </Flex>
        </Container>
      </Section>
      <StyledSection background="tertiary-light" padding="1rem 0">
        <Container>
          <Flex flexWrap="wrap" style={{ margin: "0 -0.5rem" }}>
            {doctors.map(doctor => (
              <TeamProfile
                key={doctor.slug}
                {...doctor}
                image={findImage({ images, name: doctor.image })}
              />
            ))}
          </Flex>
        </Container>
      </StyledSection>
    </Layout>
  )
}

export default Team
