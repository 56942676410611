const doctors = [
  {
    name: "Dr. Mauricio Mendes de Albuquerque",
    crm: "SC 6709",
    titles: [
      {
        rqe: "3486",
        label: "Cirurgia do Aparelho Digestivo",
      },
      {
        rqe: "3487",
        label: "Cirurgia Geral",
      },
    ],
    slug: "dr_mauricio_mendes_albuquerque",
    image: "dr_mauricio_mendes_albuquerque.png",
    resume: [
      "Graduado em Medicina pela Universidade Federal de Santa Catarina (UFSC) em 1994",
      "Residência médica em Cirurgia Geral pelo Hospital Universitário UFSC (1997) – Registro de Qualificação de Especialista – 3487",
      "Residência médica em Cirurgia do Aparelho Digestivo pelo Hospital Universitário UFSC (1998) – Registro de Qualificação de Especialista – 3486",
      "Cirurgião Geral e Preceptor da Residência Médica em Cirurgia Geral e Cirurgia do Aparelho Digestivo – Hospital Universitário UFSC de 2000 a 2015",
      "Cirurgião Geral e Preceptor da Residência Médica em Cirurgia Geral - HGCR desde 2003",
      "Professor do curso de Graduação em Medicina da Universidade do Sul de Santa Catarina (UNISUL) desde 2001",
    ],
    lattes: "http://lattes.cnpq.br/0585393225415022",
  },
  {
    name: "Dr. João Paulo Farias",
    crm: "SC 14275",
    titles: [
      {
        rqe: "11606",
        label: "Cirurgia do Aparelho Digestivo",
      },
      {
        rqe: "12237",
        label: "Endoscopia",
      },
      {
        rqe: "11726",
        label: "Cirurgia Geral",
      },
    ],
    slug: "dr_joao_paulo_farias",
    image: "dr_joao_paulo_farias.png",
    resume: [
      "Graduado em medicina pela Universidade Federal de Santa Catarina – UFSC (2007).",
      "Residência médica em Cirurgia Geral – Hospital Universitário da UFSC (2010).",
      "Residência médica em Cirurgia do aparelho digestivo – Hospital das Clínicas da Faculdade de Medicina de Ribeirão Preto – HCFMRP USP RP (2012).",
      "Especialização em Endoscopia Digestiva – Hospital das Clínicas da Faculdade de Medicina da USP – HCFMUSP (2014).",
      "Titular da Sociedade Brasileira de Endoscopia Digestiva.",
    ],
    lattes: "http://lattes.cnpq.br/7431934323347212",
  },
  {
    name: "Dr. Wilmar Athayde Gerent",
    crm: "SC 2615",
    titles: [
      {
        rqe: "108",
        label: "Cirurgia Geral",
      },
    ],
    slug: "dr_wilmar_athayde_gerent",
    image: "dr_wilmar_athayde_gerent.png",
    resume: [
      "Graduado em Medicina pela Universidade Federal de Santa Catarina (UFSC) em 1977",
      "Residência médica em Cirurgia Geral pelo Hospital Federal dos Servidores do Estado do Rio Janeiro HSE/RJ 1978 a 1979) – Registro de Qualificação de Especialista RQE: 108",
      "Professor adjunto na UFSC no Departamento de Cirurgia Geral HU UFSC durante o período de 1980 até 2017.",
      "Chefe Serviço Cirurgia Geral Imperial Hospital de Caridade desde 2002.",
      "Fellowship em Cirurgia Digestiva 1992 Alemanha -  Serviço do Prof Dr Jörg Rüdiger Siewert - ",
      "Fellowship em Cirurgia Digestiva 1997 Alemanha - University of Münster Prof Dr Norbert Senninger ",
      "Fellowship em Cirurgia Digestiva 1997 EUA -  Memorial Sloan Kettering Cancer Center Prof Dr Leslie H. Blumgart",
      "Cirurgião geral do Corpo Clínico do Hospital Baia Sul",
      "Ex-Presidente do Conselho regional de Medicina nos anos de 2005 até 2006.",
      "Ex-conselheiro Federal do CFM no período de 2014 a 2019.",
    ],
    lattes: "http://lattes.cnpq.br/0538660564386026",
  },
  {
    name: "Dr. Cristiano Denoni Freitas",
    crm: "SC 11409",
    titles: [
      {
        rqe: "8714",
        label: "Cirurgia do Aparelho Digestivo",
      },
      {
        rqe: "9339",
        label: "Coloproctologia",
      },
      {
        rqe: "20797",
        label: "Cirurgia Bariátrica",
      },
    ],
    slug: "dr_cristiano_denoni_freitas",
    image: "dr_cristiano_denoni_freitas.png",
    resume: [
      "Graduado em Medicina pela Universidade Federal de Santa Catarina (UFSC) em 2004",
      "Residência médica em Cirurgia Geral pelo Hospital Universitário UFSC (2007) – Registro de Qualificação de Especialista – 6635",
      "Residência médica em Cirurgia do Aparelho Digestivo pelo Hospital Universitário UFSC (2009) – Registro de Qualificação de Especialista – 8714",
      "Residência médica em Coloproctologia pelo Hospital Universitário Cajuru PUC-PR Curitiba PR (2011) – Registro de Qualificação de Especialista – 9339",
      "Médico preceptor da Residência médica em Cirurgia Geral do HGCR (Hospital Governador Celso Ramos) SES SC, desde 2007.",
      "Cirurgião colorretal do serviço multidisciplinar de endometriose profunda da Maternidade Carmela Dutra - SES SC,  desde 2019.",
      "Membro titular da Sociedade Brasileira de Coloproctologia (SBCP)",
      "Membro titular do Colégio Brasileiro de Cirurgiões (CBC)",
      "Membro titular do Colégio Brasileiro de Cirurgia Digestiva (CBCD)",
      "TÍtulo de especialista em área de atuação na Cirurgia Bariátrica pelo CBC/CBCD/SBCBM certificado pela AMB",
      "Membro Titular da Sociedade Brasileira de Cirurgia Minimamente Invasiva e Robótica (SOBRACIL)",
      "Certificação e habilitação em cirurgia robótica nas plataformas do robô da Vinci (Intuitive Surgical) e robô Versius (CRM Surgical)"
    ],
    lattes: "http://lattes.cnpq.br/4490209154294816"
  },
  {
    name: "Dr. Eduardo Miguel Schmidt",
    crm: "SC 7120",
    titles: [
      {
        rqe: "3330",
        label: "Cirurgia do Aparelho Digestivo",
      },
      {
        rqe: "3293",
        label: "Cirurgia Geral",
      },
    ],
    slug: "dr_eduardo_miguel_schmidt",
    image: "dr_eduardo_miguel_schmidt.png",
    resume: [
      "Graduado em Medicina pela UFSC em 1991",
      "Residência Médica em Cirurgia Geral pelo Hospital Heliópolis São Paulo/SP no período de 1992-1994.",
      "Residência Médica em Cirurgia do Aparelho Digestivo pelo Hospital Heliópolis São Paulo/SP no período de 1994-1996.",
      "Titulo de Especialista pelo Colégio Brasileiro de Cirurgiões (CBC).",
      "Título de Especialista e Membro titular do Colégio Brasileiro de Cirurgia Digestiva (CBCD) e Habilitação em Cirurgia Oncológica do Aparelho Digestivo",
      "Membro Associado da Sociedade Brasileira de Cirurgia Bariátrica e Metabólica.",
      "Título em Habilitação e Membro Titular da SOBRACIL (Sociedade Brasileira de Cirurgia Minimamente Invasiva e Robótica)",
      "Pós-graduação lato sensu - especialização em Coloproctologia - Instituto Sírio-Libanês de Ensino e Pesquisa - 2010.",
      "Estagiário Visitante no Serviço de Fígado e Hipertensão Porta - Clínica Cirúrgica II Hospital das Clínicas Disciplina de Cirurgia do Aparelho Digestivo Departamento de Gastroenterologia - Faculdade de Medicina da USP - São Paulo - SP. ",
      "Certificação e habilitação em cirurgia robótica nas plataformas do robô da Vinci (Intuitive Surgical) e robô Versius (CRM Surgical)",
    ],
  },
  {
    name: "Dr. Marcelo Borges Moreira",
    crm: "SC 15062",
    titles: [
      {
        rqe: "16707",
        label: "Cirurgia do Aparelho Digestivo",
      },
      {
        rqe: "9897",
        label: "Cirurgia Geral",
      },
    ],
    slug: "dr_marcelo_borges_moreira",
    image: "dr_marcelo_borges_moreira.png",
    resume: [
      "Especialista em Cirurgia Do Aparelho Digestivo (RQE 16707)",
      "Membro titular especialista do Colégio Brasileiro de Cirurgia Digestiva desde 2016.",
      "Formado em Medicina pela Universidade Federal de Santa Catarina em 2008. ",
      "Residência médica em Cirurgia Geral no Hospital Regional de São José 2010-2011.",
      "Cirurgião dos Hospitais Baía Sul, Caridade e Unimed",
      "Cirurgião e Preceptor dos Programas de residência médica em cirurgia do Aparelho Digestivo e Cirurgia Geral do Hospital Regional de São José - SC",
      "Diretor Geral do Hospital Regional de São José - 2019-2021"
    ],
    illnesses: [
      "Tumores do aparelho digestivo (esofago, Estomago, cólon)",
      "Doenças da vesícula biliar, vias biliares e fígado",
      "Doenças do pâncreas",
      "Tumores do cólon e reto.",
      "Cirurgia bariátrica",
      "Doenças orificiais (hemorroidas, fístulas e fissuras)"
    ],
  },
  {
    name: "Dr. Carlos Augusto Chede Rotolo",
    crm: "SC 21316",
    titles: [
      {
        rqe: "19167",
        label: "Cirurgia do Aparelho Digestivo",
      },
      {
        rqe: "16176",
        label: "Cirurgia Geral",
      },
    ],
    slug: "dr_carlos_augusto_chede_rotolo",
    image: "dr_carlos_augusto_chede_rotolo.png",
    resume: [
      "Graduado em Medicina pela Faculdade de Medicina de Petrópolis (FMP)",
      "Residência Médica em Cirurgia Geral no Hospital Governador Celso Ramos (RQE 16176) (2016 – 2018)",
      "Residência Médica em Cirurgia do Aparelho Digestivo no Hospital Regional de São José (RQE 19167) (2018 – 2020)",
      "Fellowship and Diploma in Minimal Access Surgery no World Laparoscopy Hospital – New Delhi – Índia (2019)",
      "Pós-Graduação em Coloproctologia Robótica no Hospital Israelita Albert Einstein (2020 – 2021)",
      "Preceptor do Programa de Residência Médica em Cirurgia Geral do Hospital Governador Celso Ramos e Membro Titular do Colégio Brasileiro de Cirurgiões - CBC",
    ],
  },
  {
    name: "Dr. Rafael Fernandes de Carvalho",
    crm: "SC 23261",
    titles: [
      {
        rqe: "23265",
        label: "Cirurgia Geral",
      },
    ],
    slug: "dr_rafael_fernandes_de_carvalho",
    image: "dr_rafael_fernandes_de_carvalho.png",
    resume: [
      "Graduado em Medicina pela Universidade do Sul de Santa Catarina (UNISUL) Campus Pedra Branca em 2015",
      "Pós-Graduação em Endocrinologia e Metabologia pelo Instituto de Diabetes e Endocrinologia de Florianópolis (IDEF) vinculado ao Imperial Hospital de Caridade",
      "Residência Médica em Cirurgia Geral pelo Hospital Universitário da Universidade Federal de Santa Catarina (UFSC) – Registro de Qualificação de Especialista – 23265",
      "Cirurgião no Ambulatório de Pequenos Procedimentos do Dr. Wilmar Athayde Gerent no Imperial Hospital de Caridade",
      "Cirurgião no Ambulatório de Pequenos Procedimentos no Hospital UNIMED Grande Florianópolis HUGF",
      "Cirurgião no Ambulatório de Pequenos Procedimentos no Centro Médico Hospital Baía Sul",
      "Professor no Ambulatório de Pequenas Cirurgias do Internato Médico do Curso de Graduação em Medicina da Universidade do Sul de Santa Catarina (UNISUL) Campus Pedra Branca",
      "Estagio eletivo visitante no Departamento de Oncologia Cutânea (Cirúrgica), no Hospital A.C. Camargo Câncer Center da Fundação Antônio Prudente, São Paulo, SP em 2022",
      "Professor Cirurgião Voluntário em Pequenas Cirurgias da IV e VI Missão Humanitária Amazônia realizada na região do Rio Tapajós na Unidade Básica de Saúde Fluvial ABARÉ - Pará - 2023 e 2024",
      "Membro do Grupo Brasileiro de Melanoma – GBM.org",
      "Membro da Society for Tennis Medicine and Science (STMS)",
    ],
    lattes: "https://lattes.cnpq.br/5876991697335592",
    schedule: "https://linktr.ee/dr.rafaelpequenascirurgias"
  },
]

module.exports = doctors
